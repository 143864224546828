<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width:100%;"
      >
        <el-table-column prop="user_id" align="center" width="100px" label="用户ID"></el-table-column>
        <el-table-column prop="username" align="center" width="160px" class="uname"   label="用户名"></el-table-column>
        <el-table-column align="center" width="150px" label="商品图片">
             <template slot-scope="scope">
            <div class="image">
              <img
                :src="scope.row.thumb"
                alt=""
                width="100"
                v-if="scope.row.thumb"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="标题"></el-table-column>
        <el-table-column prop="order_sn" align="center"  label="订单号"></el-table-column>
        <el-table-column prop="quantity"  width="100px" align="center" label="购买数量"></el-table-column>
        <el-table-column prop="pay_amount" align="center" label="支付金额"></el-table-column>
        <el-table-column prop="create_time" align="center" label="交易时间"></el-table-column>
        <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="searchOpen(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="searchOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PostfirmHistory} from "@/api/Firm/firm.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num,
      id:this.familyId, //家庭ID 无
      user_id:this.userid //用户id 无
    }
    console.log(data);
    this.tableData=[]
    this.PostfirmHistory(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num,
      id:this.familyId, //家庭ID 无
      user_id:this.userid //用户id 无
    }
    console.log(data);
    this.tableData=[]
    this.PostfirmHistory(data);
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //获取家庭列表网络请求
    async PostfirmHistory(data){
        let res=await PostfirmHistory(data);
        console.log(res);
        if(res.data.code==1){
            this.tableData=res.data.data.data;
        }else{
            this.$message.error(res.data.message);
        } 
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.familyId=this.$route.query.id
    this.userid=this.$route.query.userid
    var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num,
      id:this.familyId, //家庭ID 无
      user_id:this.userid //用户id 无
    }
    this.PostfirmHistory(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.image{
  display: flex;
  justify-content: center;
}
img{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    display: flex;
    justify-items: center;
    align-items: center;
    background: #c0c4cc;
    overflow: hidden;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
/* .uname{
  white-space:normal;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  background-color: pink;
} */
</style>